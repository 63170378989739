#arrowDown {
  transform: rotate(180deg); /* Arrow pointing down */
}

#arrowDown:hover {
  transform: rotate(180deg) scale(1.3); /* Rotate and zoom */
  transition: transform 0.3s ease; /* Smooth transition */
}

#arrowUp:hover {
  transform: scale(1.3); /* Zoom by 10% */

  transition: transform 0.3s ease; /* Smooth transition */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  filter: invert(0); /* Default for light mode */
}

/* Tailwind's dark mode */
.dark input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert colors in dark mode */
}
